import { useEffect } from 'react';
import { setUrlAddress } from 'utils/setUrlAddress';

/**
 * @description this effect responsible for setting at SessionStorage url from last user page visited
 * */
const useSetAddress = () => {
  useEffect(() => {
    const urlState = setUrlAddress();
    sessionStorage.setItem('urlState', urlState);
  }, []);
};

export default useSetAddress;
