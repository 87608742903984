import { IconButton, Tooltip } from '@material-ui/core';
import { Print, PrintDisabledOutlined } from '@material-ui/icons';
import { storagePrintKey } from 'const';
import React, { useEffect, useState } from 'react';

const ForPrintToggle = () => {
  const [forPrint, setForPrint] = useState(
    !!localStorage.getItem(storagePrintKey)
  );

  useEffect(() => {
    if (forPrint) {
      localStorage.setItem(storagePrintKey, 'true');
    } else {
      localStorage.removeItem(storagePrintKey);
    }
  }, [forPrint]);

  return (
    <Tooltip title="For print">
      <IconButton onClick={() => setForPrint((prev) => !prev)}>
        {forPrint ? (
          <Print color="secondary" />
        ) : (
          <PrintDisabledOutlined color="secondary" />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ForPrintToggle;
