import React from 'react';
import { Grid, Paper, Button, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { selectLoggedIn, selectRole } from 'store/auth/selectors';
import useQuery from 'hooks/useQuery';
import useSetToken from 'hooks/useSetTokens';
import GoogleButton from '../components/ui/GoogleButton';
import getTokensFromLocalStorage from '../utils/getTokensFromLocalStorage';
import { checkIncludesTokenString } from '../utils/checkIncludesTokensString';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    minHeight: '100vh',
    background: `url(${process.env.PUBLIC_URL}/images/register.png) center center no-repeat;`,
    backgroundSize: 'cover',
  },
  formWrapper: {
    maxWidth: '420px',
    padding: theme.spacing(3),
    textAlign: 'center',
    backgroundColor: '#fff',
    boxShadow: '0 -25px 37.7px 11.3px rgba(8,143,220,0.07)',
  },
  formSbtButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: 'initial',
  },
  copyright: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
}));

const Login = () => {
  const { refreshToken: token0, accessToken: token1 } =
    getTokensFromLocalStorage();
  const classes = useStyles();
  const query = useQuery();
  const isLoggedIn = useSelector(selectLoggedIn);
  const role = useSelector(selectRole);
  const accessToken = query.get('accessToken');
  const refreshToken = query.get('refreshToken');
  const isAdmin = role === 'ADMIN';
  const arrayOfTokens = [token0, token1];

  useSetToken({ accessToken, refreshToken });

  const handleOpenGoogleLoginScreen = (): void => {
    window.location.href = process.env.REACT_APP_GOOGLE_AUTH_PATH;
  };

  let urlState = sessionStorage.getItem('urlState');
  const isUrlHasToken = checkIncludesTokenString(arrayOfTokens, urlState);
  urlState = isUrlHasToken ? '' : urlState;

  return isAdmin ? (
    <Redirect to={urlState?.length ? urlState : '/folders/root'} />
  ) : isLoggedIn ? (
    <Redirect to={urlState?.length ? urlState : '/dashboard'} />
  ) : (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        item
        className={classes.formWrapper}
        lg={4}
        elevation={0}
        component={Paper}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.svg`}
          alt="Logo"
          style={{ marginBottom: '10px' }}
        />

        <Button
          fullWidth
          size="large"
          variant="outlined"
          color="primary"
          className={classes.formSbtButton}
          onClick={handleOpenGoogleLoginScreen}
        >
          <GoogleButton listClass={{ marginRight: '10px' }} />
          Sign in with Google
        </Button>

        <Typography variant="caption">
          You will need a corporate Google account to proceed
        </Typography>
      </Grid>

      <Grid
        item
        className={classes.copyright}
      >
        <Typography>
          Copyright © <Link href="https://inventorsoft.co">InventorSoft</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
