import { API } from '../API';
import { apiEndPoints } from '../../const';
const { CV_ENDPOINT } = apiEndPoints;

class IMAGEAPI extends API {
  async create(cvId: number, userImage: any) {
    await this.post(`${CV_ENDPOINT}/${cvId}/upload-picture`, userImage);
  }

  async remove(cvId: number | undefined) {
    await this.put(`${CV_ENDPOINT}/${cvId}/delete-picture`);
  }
}

export const imageApi = new IMAGEAPI();
