import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose, isNil } from 'ramda';
import { cvApi } from 'api/services/cv.api';
import {
  selectDataForTemplate,
  selectIsDownloadWithoutLogo,
} from 'store/template/selectors';
import { actions } from 'store/template/slice';
import { actions as iconsActions } from 'store/icons/slice';
import downloadFileFromBase64 from 'utils/downloadFileFromBase64';
import htmlToString from 'utils/htmlToString';
import addMetaToPDF from 'utils/addMetaToPDF';
import findTemplate from 'utils/findTemplate';
import { ICV } from 'models/cv';
import { selectUserImage } from 'store/cv/selectors';
import TemplateStorage from 'components/TemplateStorage';
import { imageUrlToBase64 } from 'utils/imageUrlToBase64';
import { AppDispatch } from 'store';

const DownloadTemplate = () => {
  const hiddenTemplateRef = useRef<HTMLDivElement | null>(null);
  const dataForTemplate: ICV | null = useSelector(selectDataForTemplate);
  const downloadWithoutLogo: Boolean = useSelector(selectIsDownloadWithoutLogo);
  const dispatch = useDispatch<AppDispatch>();
  const userImage = useSelector(selectUserImage);

  useEffect(() => {
    const getImage = async (path: string) => {
      const str = await imageUrlToBase64(`${process.env.PUBLIC_URL}${path}`);
      return str;
    };

    (async () => {
      const email = await getImage('/images/email.png');
      const calendar = await getImage('/images/calendar.png');

      dispatch(iconsActions.setCalendarIcon(email));
      dispatch(iconsActions.setEmailIcon(calendar));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!isNil(dataForTemplate)) {
        await handleDownloadPDF(dataForTemplate.cvName);
        // clear state after downloading pdf is required
        dispatch(actions.dataForTemplateReceived(null));
        dispatch(actions.setIsDownloadWithoutLogo(false));
      }
    })();
  }, [dataForTemplate]);

  /**
   * @description
   * downloadFromTemplate will findHiddenTemplate by class name,
   * convert HTMLElement to string and make POST request to fetch PDF file,
   * and based on response (content, contentType, name) we will download PDF
   * */
  async function handleDownloadPDF(name: string) {
    const downloadFromTemplate = compose(
      async (str: string) => {
        const data = await cvApi.downloadPDF({
          html: str,
          includeAvatar: !downloadWithoutLogo,
          name,
        });
        downloadFileFromBase64(data);
      },
      htmlToString,
      addMetaToPDF,
      findTemplate
    );

    await downloadFromTemplate(hiddenTemplateRef);
  }

  return (
    <div>
      {!isNil(dataForTemplate) && (
        <div style={{ visibility: 'hidden' }} ref={hiddenTemplateRef}>
          <TemplateStorage
            cv={dataForTemplate as ICV}
            forDownload
            userImage={userImage}
          />
        </div>
      )}
    </div>
  );
};

export default DownloadTemplate;
