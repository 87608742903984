export const checkIncludesTokenString = (array: any, urlString: any) => {
  if (array && urlString) {
    for (let i = 0; i < array.length; i++) {
      if (urlString?.includes(array[i])) {
        return true;
      }
    }
  }

  return false;
};
