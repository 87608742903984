export const dateSubstraction = (start?: string, end?: string): number => {
  if (start && end) {
    const substractionMillisec =
      new Date(end).getTime() - new Date(start).getTime();

    const months = Math.round(
      substractionMillisec / (1000 * 60 * 60 * 24 * 30)
    );

    return months;
  }
  return 1;
};
