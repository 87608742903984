export const imageUrlToBase64 = async (
  url: string
): Promise<string | ArrayBuffer | null> => {
  const fetchImage = await fetch(url);
  const blob = await fetchImage.blob();

  return new Promise((onSuccess, onError) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        onSuccess(reader.result);
      };
    } catch (error) {
      onError(error);
    }
  });
};
