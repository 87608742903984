import { Lens, curry, lensProp, over } from 'ramda';

const transformJSON = curry(
  <T>(
    lensPropertyName: string,
    jsonMethod: 'stringify' | 'parse',
    object: T
  ): T => {
    const propLens = lensProp(lensPropertyName as unknown as never);
    return over(
      propLens as unknown as Lens<unknown, any>,
      JSON[jsonMethod]
    )(object) as T;
  }
);

export default transformJSON;
