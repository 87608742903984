export const formatPeriod = (monthCount: number): string => {
  const monthsInYear = 12;
  if (monthCount < monthsInYear)
    return `${monthCount} month${getPlural(monthCount, 1.1)}`;

  const years = roundToOneDecimal(monthCount / monthsInYear);
  return (years + ` year${getPlural(years, 2)}`).replace('.', ',');
};

function roundToOneDecimal(value: number): number {
  return Math.round(value * 10) / 10;
}

function getPlural(value: number, compareNum: number): string {
  return value >= compareNum ? 's' : '';
}
