import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';
import CancelIcon from '@material-ui/icons/Cancel';
import Loading from 'components/Loading';
import { imageApi } from '../api/services/image.api';
import { dataURLtoFile } from '../utils/dataUrlToFile';
import { selectCurrentCVId } from 'store/cv/selectors';
import {
  setEmptyCroppedImage,
  getCroppedImage,
} from '../utils/croppedImageStorage';
import { fetchOneCV } from 'store/cv/thunks';

const ImageCrop = ({
  imageSrc,
  onCrop,
  setEditorRef,
  scaleValue,
  onScaleChange,
  closeModalWindow,
  isEditImg,
  editImage,
  userImage,
}) => {
  const [load, setLoad] = useState(false);
  const avatarSize = 450;
  const dispatch = useDispatch();
  const currentCVId = useSelector(selectCurrentCVId);
  const handleClick = async (e) => {
    if (!isEditImg) return;
    e.preventDefault();
    onCrop();
    setLoad(true);
    if (isEditImg) {
      const file = getCroppedImage();
      const cropFile = dataURLtoFile(file, 'file');
      const fd = new FormData();
      fd.append('file', cropFile);
      await imageApi.create(currentCVId, fd);
      setEmptyCroppedImage();
      dispatch(fetchOneCV(currentCVId));
    }
    editImage();
    setLoad(false);
    closeModalWindow();
  };

  return (
    <>
      {load && <Loading />}
      {!load && (
        <div className="editorOverlayOuter">
          <CancelIcon
            className="cross-avatar-editor"
            onClick={closeModalWindow}
          />
          <div className="editorOverlayInner">
            <div className="editorModalContent clearfix">
              <div className="cropCnt">
                <div className="avatar-editor">
                  <AvatarEditor
                    borderRadius={avatarSize / 2}
                    image={imageSrc}
                    border={50}
                    scale={scaleValue}
                    rotate={0}
                    ref={setEditorRef}
                    className="cropCanvas"
                    width={avatarSize}
                    height={avatarSize}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <input
                    style={{ width: '100%', cursor: 'pointer' }}
                    type="range"
                    value={scaleValue}
                    name="points"
                    min="1"
                    max="10"
                    onChange={onScaleChange}
                  />
                  <div className="editor-buttons">
                    <Button
                      onClick={handleClick}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      {isEditImg && userImage ? 'Change' : 'Save'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCrop;
