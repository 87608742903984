import React from 'react';

function withShouldUpdate(
  conditionChecker: any,
  WrappedComponent: React.FunctionComponent<any>
) {
  return React.memo(
    (props) => <WrappedComponent {...props} />,
    conditionChecker
  );
}

export default withShouldUpdate;
