import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  emailIcon: string;
  calendarIcon: string;
}

const initialState: InitialState = {
  emailIcon: '',
  calendarIcon: '',
};

export const { reducer: iconsReducer, actions } = createSlice({
  name: 'icons',
  initialState,
  reducers: {
    setEmailIcon(state, action) {
      state.emailIcon = action.payload;
    },

    setCalendarIcon(state, action) {
      state.calendarIcon = action.payload;
    },
  },
});
