import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
    title: String,
    isOpen: Boolean,
    modalFolderId: number,
    modalCvId: number,
    modalUserId: number
}

const initialState: InitialState = {
    title: 'item',
    isOpen: false,
    modalFolderId: 0,
    modalCvId: 0,
    modalUserId: 0
};

export const { reducer: modalFolderReducer, actions } = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setCurrentModalTitle(state, action) {
            state.title = action.payload;
        },
        changeVisibleModal(state, action) {
            state.isOpen = action.payload;
        },
        setCurrentfolderId(state, action) {
            state.modalFolderId = action.payload;
        },
        setCurrentCvId(state, action) {
            state.modalCvId = action.payload;
        },
        setCurrentUserId(state, action) {
            state.modalUserId = action.payload;
        }
    }
    });