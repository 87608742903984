import React, { useState } from 'react';
import 'date-fns';
import '../index.css';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentCVId } from 'store/cv/selectors';
import * as Yup from 'yup';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddAPhotoSharpIcon from '@material-ui/icons/AddAPhotoSharp';
import { Grid, Typography, Button } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Formik, Form } from 'formik';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { append, curry, remove } from 'ramda';
import { setCroppedImage } from '../utils/croppedImageStorage';
import { imageApi } from '../api/services/image.api';
import {
  cropperModalOpen,
  cropperModalClose,
} from '../utils/cropperModalhandler';
import { ICV, Template } from 'models/cv';
import { fetchOneCV } from 'store/cv/thunks';
import {
  EducationEntity,
  LanguageEntity,
  ProjectEntity,
} from 'entities/CVEntity';
import AddSection from './AddSection';
import CustomTextField from './CustomTextField';
import MemoDatePicker from './MemoDatePicker';
import CustomSelect from './CustomSelect';
import CustomAutocomplete from './CustomAutocomplete';
import ImageCropper from './ImageCropper';
import CustomAlert from './CustomAlert';
import { dateSubstraction } from 'utils/dateSubstraction';
import { AppDispatch } from 'store';

type Props = {
  initialValues: ICV;
  submitHandler: (values: ICV) => Promise<void>;
  validationSchema?: Yup.ObjectSchema<{ email: string }>;
  userImage?: string;
  isEdit?: Boolean;
};

const initialValidationSchema = Yup.object<ICV>().shape({
  cvName: Yup.string().required('Required'),
  jobTitle: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email!'),
  dateOfBirth: Yup.date().nullable(),
  professionalSummary: Yup.string().required('Required'),
  skills: Yup.array(Yup.string()),
  programmingLanguages: Yup.array(Yup.string()).required('Required'),
  frameworksAndLibraries: Yup.array(Yup.string()).required('Required'),
  technologiesAndDatabases: Yup.array(Yup.string()).required('Required'),
  others: Yup.array(Yup.string()).required('Required'),
  certifications: Yup.string(),
  education: Yup.array().of(
    Yup.object().shape({
      school: Yup.string().required('Required'),
      degree: Yup.string().required('Required'),
      educationStartDate: Yup.date(),
      educationEndDate: Yup.date(),
    })
  ),
  languages: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required('Required'),
      spoken: Yup.string().required('Required'),
    })
  ),
  hobby: Yup.string(),
  projects: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      period: Yup.number()
        .typeError('Period must be number')
        .min(1, 'At least one month')
        .required('Required'),
      projectDescription: Yup.string().required('Required'),
      position: Yup.string().required('Required'),
      responsibilities: Yup.string().required('Required'),
      tools: Yup.array(Yup.string()).required('Required'),
    })
  ),
});

/* use styles from ui library */
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        padding: '0 60px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 15px',
      },
    },
    formField: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    formSection: {
      marginTop: theme.spacing(4),
    },
  })
);

const CVForm = ({
  initialValues,
  submitHandler,
  validationSchema,
  userImage,
  isEdit,
}: Props) => {
  const classes = useStyles();
  const currentCVId = useSelector(selectCurrentCVId);
  const dispatch = useDispatch<AppDispatch>();
  const [editor, setEditor] = useState<any>(null);
  const [scaleValue, setScaleValue] = useState<number>(1);
  const [modalClass, setModalClass] = useState<string>('');
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [isEditImg, setEditImg] = useState<boolean>(false);
  const [snackText, setSnackText] = useState<string>('changed');
  const [selectedImage, setSelectedImage] = useState<any>('');
  const [userProfilePic, setUserProfilePic] = useState<any>('');
  const skills = [
    {
      id: 'skills',
      label: 'Skills',
    },
    {
      id: 'programmingLanguages',
      label: 'Programming languages',
    },
    {
      id: 'frameworksAndLibraries',
      label: 'Frameworks/Libraries',
    },
    {
      id: 'technologiesAndDatabases',
      label: 'Technologies/Databases',
    },
    {
      id: 'others',
      label: 'Others',
    },
  ];
  const levels = [
    'beginner',
    'elementary',
    'intermediate',
    'upper-intermediate',
    'advanced',
    'proficiency',
  ];
  const finalValidationSchema = validationSchema
    ? initialValidationSchema.concat(validationSchema)
    : initialValidationSchema;

  const setEditorRef = (editor: any) => setEditor(editor);

  const onCrop = () => {
    if (editor.getImageScaledToCanvas === null) return;

    if (editor !== null) {
      const url = editor.getImageScaledToCanvas().toDataURL();
      setUserProfilePic(url);
      setCroppedImage(url);
    }
  };

  const onScaleChange = (scaleChangeEvent: any) => {
    const scaleValue = parseFloat(scaleChangeEvent.target.value);
    setScaleValue(scaleValue);
  };

  const editImage = () => {
    setSnackText('changed');
    setOpenSnack(true);
  };

  const profilePicChange = (fileChangeEvent: any) => {
    setEditImg(true);
    const uploadImage = fileChangeEvent.target.files[0];
    const { type } = uploadImage;
    if (
      !(
        type.endsWith('jpeg') ||
        type.endsWith('png') ||
        type.endsWith('jpg') ||
        type.endsWith('gif')
      )
    ) {
    } else {
      setSelectedImage(uploadImage);
      cropperModalOpen(setModalClass);
    }
    fileChangeEvent.target.value = '';
  };

  const closeModalWindow = () => cropperModalClose(setModalClass);

  const removeImage = async () => {
    if (currentCVId) {
      await imageApi.remove(currentCVId);
      dispatch(fetchOneCV(currentCVId));
    }
    userProfilePic && setUserProfilePic('');
    setSnackText('removed');
    setOpenSnack(true);
    setEditImg(false);
  };

  const getPrevPeriodWithDates = (values: ICV, index: number): string => {
    const project = values.projects[index];
    if (project.period) return '';
    if (project.projectStartDate) {
      return ` (prev period ${dateSubstraction(
        values.projects[index].projectStartDate,
        values.projects[index].projectEndDate
      )})`;
    }

    return '';
  };

  /* render */
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={finalValidationSchema}
        onSubmit={submitHandler}
      >
        {({ values, setFieldValue, errors, dirty, handleSubmit }) => {
          const handleSectionActions = curry(
            (name: string, template: Template) => ({
              handleRemoveSection(i: number) {
                return setFieldValue(name, remove(i, 1, values[name]));
              },
              handleAddSection() {
                return setFieldValue(name, append(template, values[name]));
              },
            })
          );

          return (
            <Form onSubmit={(e) => e.preventDefault()}>
              <Grid
                container
                spacing={2}
                className={classes.root}
              >
                <Grid
                  item
                  xs={6}
                  className="name-field"
                >
                  <CustomTextField
                    name="cvName"
                    fullWidth
                    variant="outlined"
                    label="Name of your cv"
                    className={classes.formField}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                >
                  <div className="relative">
                    <input
                      id="file"
                      type="file"
                      onChange={profilePicChange}
                      className="file-input"
                    />
                    {(!!userProfilePic || !!userImage) && (
                      <DeleteForeverIcon
                        style={{ width: 35, height: 35, fill: '#de5a49' }}
                        className="trash-icon"
                        onClick={removeImage}
                      />
                    )}
                    {!userProfilePic.length && !userImage && (
                      <div className="photo-add">
                        <AddAPhotoSharpIcon style={{ width: 70, height: 70 }} />
                      </div>
                    )}
                    {(userProfilePic.length || !!userImage) && (
                      <img
                        src={userProfilePic ? userProfilePic : userImage}
                        alt="upload"
                        className="upload-image"
                      />
                    )}
                  </div>
                  <div className={'modal ' + modalClass}>
                    <div className="modal-inner">
                      <ImageCropper
                        userImage={userImage}
                        editImage={editImage}
                        imageSrc={selectedImage}
                        setEditorRef={setEditorRef}
                        onCrop={onCrop}
                        scaleValue={scaleValue}
                        onScaleChange={onScaleChange}
                        closeModalWindow={closeModalWindow}
                        isEditImg={isEditImg}
                      />
                    </div>
                  </div>
                </Grid>
                {/* personal details */}
                <Grid
                  item
                  className={classes.formSection}
                  component={Typography}
                  variant="h6"
                  xs={12}
                >
                  <b>Personal Details</b>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <CustomTextField
                    name="jobTitle"
                    fullWidth
                    variant="outlined"
                    label="Job title"
                    placeholder="Senior Java/ Frontend / QA Engineer"
                    className={classes.formField}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <CustomTextField
                    fullWidth
                    variant="outlined"
                    label="Email"
                    className={classes.formField}
                    name="email"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <CustomTextField
                    fullWidth
                    variant="outlined"
                    label="First Name"
                    className={classes.formField}
                    name="firstName"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <CustomTextField
                    fullWidth
                    variant="outlined"
                    label="Last Name"
                    className={classes.formField}
                    name="lastName"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <MemoDatePicker utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.formField}
                      margin="normal"
                      id="dateOfBirth"
                      label="Birthdate"
                      format="MM/dd/yyyy"
                      value={values?.dateOfBirth}
                      onChange={(value) => setFieldValue('dateOfBirth', value)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MemoDatePicker>
                </Grid>

                {/* professional summary */}
                <Grid
                  item
                  component={Typography}
                  className={classes.formSection}
                  variant="h6"
                  xs={12}
                >
                  <b>Professional summary</b>
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <CustomTextField
                    fullWidth
                    multiline
                    variant="outlined"
                    name="professionalSummary"
                    placeholder="Please write 5-8 sentences explaining your general experience: years of experience, core technologies in which you are expert, methodologies (like Scrum) with which you are familiar etc."
                    minRows={10}
                    className={classes.formField}
                  />
                </Grid>

                {/* skills and abilities */}
                <Grid
                  item
                  component={Typography}
                  className={classes.formSection}
                  variant="h6"
                  xs={12}
                >
                  <b>Skills & Abilities</b>
                </Grid>

                {skills.map(({ id, label }) => (
                  <Grid
                    key={id}
                    item
                    xs={12}
                  >
                    <CustomAutocomplete
                      placeholder="Add item"
                      label={label}
                      name={id}
                      value={values[id]}
                      onChange={(_: any, value: any) =>
                        setFieldValue(id, value)
                      }
                    />
                  </Grid>
                ))}

                {/* education */}
                <Grid
                  item
                  component={Typography}
                  className={classes.formSection}
                  variant="h6"
                  xs={12}
                >
                  <b>Education</b>
                </Grid>

                <AddSection
                  {...handleSectionActions('education', new EducationEntity())}
                  list={values?.education}
                  render={(item, index) => (
                    <Grid
                      item
                      xs={12}
                      key={index}
                    >
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                          md={6}
                        >
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            label="Education"
                            placeholder="University name"
                            className={classes.formField}
                            name={`education.${index}.school`}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                        >
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            label="Degree and Faculty"
                            placeholder="Master's degree in Computer Science"
                            className={classes.formField}
                            name={`education.${index}.degree`}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                        >
                          <MemoDatePicker utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              margin="normal"
                              label="Start date"
                              format="yyyy"
                              className={classes.formField}
                              id={`education.${index}.educationStartDate`}
                              value={values.education[index].educationStartDate}
                              onChange={(value) =>
                                setFieldValue(
                                  `education[${index}].educationStartDate`,
                                  value
                                )
                              }
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MemoDatePicker>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                        >
                          <MemoDatePicker utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              margin="normal"
                              label="End date"
                              format="yyyy"
                              className={classes.formField}
                              id={`education.${index}.educationEndDate`}
                              value={values.education[index].educationEndDate}
                              onChange={(value) =>
                                setFieldValue(
                                  `education[${index}].educationEndDate`,
                                  value
                                )
                              }
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MemoDatePicker>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                />

                {/* certifications */}
                <Grid
                  item
                  component={Typography}
                  className={classes.formSection}
                  variant="h6"
                  xs={12}
                >
                  <b>Certifications</b>
                </Grid>

                <CustomTextField
                  fullWidth
                  variant="outlined"
                  label="Certifications"
                  name="certifications"
                  multiline
                  minRows={5}
                />

                {/* languages */}
                <Grid
                  item
                  component={Typography}
                  className={classes.formSection}
                  variant="h6"
                  xs={12}
                >
                  <b>Languages</b>
                </Grid>

                <Grid
                  item
                  xs={12}
                  component={AddSection}
                  list={values?.languages}
                  render={(item: any, index: number) => (
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                      >
                        <CustomTextField
                          fullWidth
                          variant="outlined"
                          label="Language"
                          name={`languages.${index}.language`}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={6}
                      >
                        <CustomSelect
                          variant="outlined"
                          label="Level"
                          name={`languages.${index}.spoken`}
                          list={levels}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {...handleSectionActions('languages', new LanguageEntity())}
                />

                {/* project experience */}
                <Grid
                  item
                  component={Typography}
                  className={classes.formSection}
                  variant="h6"
                  xs={12}
                >
                  <b>Project Experience</b>
                </Grid>

                <AddSection
                  {...handleSectionActions('projects', new ProjectEntity())}
                  list={values?.projects}
                  render={(item, index) => (
                    <Grid
                      item
                      xs={12}
                      key={index}
                    >
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                          md={6}
                        >
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            label="Name"
                            className={classes.formField}
                            name={`projects.${index}.name`}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                        >
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            label="Country"
                            className={classes.formField}
                            name={`projects.${index}.country`}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                        >
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            label="Position"
                            className={classes.formField}
                            name={`projects.${index}.position`}
                            value={values.projects[index].position}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                        >
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            label={`Period (Months)${getPrevPeriodWithDates(
                              values,
                              index
                            )}`}
                            style={{
                              backgroundColor: getPrevPeriodWithDates(
                                values,
                                index
                              )
                                ? '#ffcbd1'
                                : '',
                            }}
                            className={classes.formField}
                            name={`projects.${index}.period`}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={12}
                        >
                          <CustomTextField
                            fullWidth
                            multiline
                            minRows={10}
                            variant="outlined"
                            label="Responsibilities"
                            placeholder="Please describe which task you was working on. Also describe complex tasks on the project if there were any. For example: implemented real time chat using websocket technology etc."
                            className={classes.formField}
                            name={`projects.${index}.responsibilities`}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                        >
                          <CustomTextField
                            fullWidth
                            multiline
                            variant="outlined"
                            minRows={10}
                            label="Project description"
                            className={classes.formField}
                            name={`projects.${index}.projectDescription`}
                            helperText="Please add projects starting from latest to oldest"
                            placeholder="Please write 5-8 sentences about this project. What project is doing, to which industry it is related. What was the team size. Under which methodology you was working. What was your responsibilities on the project. Which complex components you developed."
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                        >
                          <CustomAutocomplete
                            label="Tools & Technologies"
                            placeholder="add tool"
                            name={`projects[${index}].tools`}
                            value={values.projects[index].tools}
                            onChange={(_: any, value: any) =>
                              setFieldValue(`projects[${index}].tools`, value)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                />

                {/* hobby */}
                <Grid
                  item
                  component={Typography}
                  className={classes.formSection}
                  variant="h6"
                  xs={12}
                >
                  <b>Hobby</b>
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <CustomTextField
                    fullWidth
                    multiline
                    minRows={5}
                    variant="outlined"
                    label="Hobby"
                    className={classes.formField}
                    name="hobby"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: 'right' }}
                >
                  <Button
                    onClick={() => handleSubmit()}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={!dirty}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <CustomAlert
        content={`Image has been ${snackText}`}
        open={openSnack}
        closeHandler={() => setOpenSnack(false)}
      />
    </>
  );
};

export default CVForm;
