import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { GetApp, GetAppOutlined } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { actions } from 'store/template/slice';
import { DOWNLOAD_MESSAGE, DOWNLOAD_WITHOUT_LOGO_MESSAGE } from '../const';
import { AppDispatch } from 'store';

type Props = {
  toDispatch: any;
  withoutCompanyLogo: Boolean;
};

function DownloadCVIcon({ toDispatch, withoutCompanyLogo }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Tooltip
      title={
        withoutCompanyLogo ? DOWNLOAD_WITHOUT_LOGO_MESSAGE : DOWNLOAD_MESSAGE
      }
    >
      <IconButton
        onClick={() => {
          dispatch(toDispatch);
          if (withoutCompanyLogo)
            dispatch(actions.setIsDownloadWithoutLogo(true));
        }}
      >
        {withoutCompanyLogo ? (
          <GetAppOutlined color="primary" />
        ) : (
          <GetApp color="primary" />
        )}
      </IconButton>
    </Tooltip>
  );
}

export default DownloadCVIcon;
