import { IUser } from 'models/user';
import { IApiCRUD } from 'models/api';
import { AxiosResponse } from 'axios';
import { API } from '../API';
import { apiEndPoints } from '../../const';

const { USERS_ENDPOINT } = apiEndPoints;
class UserApi extends API implements IApiCRUD<IUser> {
  public async fetchOne(id: number) {
    const { data } = await this.get<IUser>(`${USERS_ENDPOINT}/${id}`);
    return data;
  }

  public async fetchAll() {
    const { data } = await this.get<IUser[]>(`${USERS_ENDPOINT}`);
    return data;
  }

  public async update(payload: IUser) {
    const { data } = await this.put<IUser>(`${USERS_ENDPOINT}`, payload);
    return data;
  }

  public async remove(id: number): Promise<AxiosResponse> {
    return this.delete(`${USERS_ENDPOINT}/${id}`);
  }

  public async fetchProfile() {
    const { data } = await this.get<IUser>(`${USERS_ENDPOINT}/profile`);
    return data;
  }
}

export const userApi = new UserApi();
