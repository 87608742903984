import {
  AbilityBuilder,
  MongoAbility,
  MongoQuery,
  createMongoAbility,
} from '@casl/ability';
import { __, equals, ifElse } from 'ramda';
import { Role } from '../models/auth';

export type Actions = 'create' | 'read' | 'update' | 'delete';
export type Subjects =
  | 'CV'
  | 'Dashboard'
  | 'Folders'
  | 'Templates'
  | 'OutsideCV';
type AppAbility = MongoAbility<[Actions, Subjects], MongoQuery>;

export const defineAbilityForUser = (can: any, cannot: any) => {
  can('read', 'CV');
  can('create', 'CV');
  can('update', 'CV');
  can('read', 'Dashboard');
  can('read', 'Templates');
  cannot('read', 'Folders');
  cannot('read', 'Users');
  cannot('read', 'OutsideCV');
};

export const defineAbilityForAdmin = (can: any, cannot: any) => {
  can('read', 'CV');
  can('create', 'CV');
  can('update', 'CV');
  can('read', 'OutsideCV');
  can('create', 'OutsideCV');
  can('update', 'OutsideCV');
  can('read', 'Dashboard');
  can('read', 'Templates');
  can('read', 'Folders');
  can('update', 'Folders');
  can('create', 'Folders');
  can('delete', 'Folders');
  can('read', 'Users');
};

export const updateAbility = (ability: AppAbility, role: Role) => {
  const { rules, can, cannot } = new AbilityBuilder<AppAbility>(
    createMongoAbility
  );

  ifElse(
    equals(__, 'USER'),
    () => defineAbilityForUser(can, cannot),
    () => defineAbilityForAdmin(can, cannot)
  )(role || '');

  ability.update(rules);
};

export const ability = createMongoAbility<[Actions, Subjects]>();
