import { defaultBlack } from 'const/colors';
import React from 'react';

interface Props {
  content: string;
  fontSize?: string;
  paddingTop?: number;
  forDownload: boolean;
}

const SectionHeader = ({
  content,
  fontSize = '24px',
  paddingTop = 0,
  forDownload,
}: Props) => {
  const headerUnderline = () => (
    <div
      style={{
        width: '70px',
        height: '1px',
        marginTop: forDownload ? '2px' : '',
        backgroundColor: defaultBlack,
      }}
    ></div>
  );

  return (
    <td
      style={{
        color: defaultBlack,
        textAlign: 'left',
        fontSize,
        fontFamily: 'Montserrat SemiBold',
      }}
      colSpan={2}
    >
      <div
        style={{
          paddingTop,
        }}
      >
        {content}
      </div>
      {headerUnderline()}
    </td>
  );
};

export default SectionHeader;
