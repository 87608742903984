import { combineReducers } from 'redux';
import { foldersReducer as folders } from './folder/slice';
import { authReducer as auth } from './auth/slice';
import { cvReducer as cv } from './cv/slice';
import { userReducer as user } from './user/slice';
import { templateReducer as template } from './template/slice';
import { modalFolderReducer as modal } from './modal/slice';
import { iconsReducer as icons } from './icons/slice';

const rootReducer = combineReducers({
  auth,
  folders,
  cv,
  user,
  template,
  modal,
  icons,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
