import { defaultBlack } from 'const/colors';
import React from 'react';

interface Props {
  content: string;
  height?: string;
}

const ListHeader = ({ content, height = '25px' }: Props) => {
  const bullet = () => (
    <span
      style={{
        position: 'absolute',
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: defaultBlack,
        top: 0,
        left: -25,
      }}
    ></span>
  );

  return (
    <div style={{ position: 'relative' }}>
      {bullet()}
      <div
        style={{
          height,
        }}
      ></div>
      <div
        style={{
          color: defaultBlack,
          position: 'absolute',
          fontSize: '18px',
          fontFamily: 'Montserrat Medium',
          top: '-9px',
          width: '100%',
          margin: '0',
        }}
      >
        {content}
      </div>
    </div>
  );
};

export default ListHeader;
