import React from 'react';
import DownloadCVIcon from './DownloadCVIcon';

interface Props {
  toDispatch: any;
}

const DownloadButtons = ({ toDispatch }: Props) => {
  const saveButtons = [
    {
      withLogo: false,
    },
    {
      withLogo: true,
    },
  ];

  return (
    <>
      {saveButtons.map((item, index) => (
        <DownloadCVIcon
          key={index}
          withoutCompanyLogo={item.withLogo}
          toDispatch={toDispatch}
        />
      ))}
    </>
  );
};

export default DownloadButtons;
