import React from 'react';

interface Props {
  checkLongerSkillSet: (
    leftColumn?: number[],
    rightColumn?: number[]
  ) => string;
  side: 'right' | 'left';
  content: JSX.Element;
}

const SkillColumn = ({ checkLongerSkillSet, side, content }: Props) => {
  const borderLine = {
    paddingLeft: '20px',
    borderLeft: '2px solid black',
    marginTop: '20px',
  };

  return (
    <td
      style={{
        width: '49%',
        position: 'relative',
      }}
    >
      <div
        style={{
          ...borderLine,
          position: checkLongerSkillSet() !== side ? 'absolute' : 'static',
          top: 0,
          width: '100%',
        }}
      >
        {content}
      </div>
    </td>
  );
};

export default SkillColumn;
