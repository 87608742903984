export const tokenTypes = {
  REFRESH_TOKEN: 'refresh-token',
  ACCESS_TOKEN: 'access-token',
};

export const statuses = {
  SUCCESSFULL_CREATE_CV_STATUS: 'CV has been created successfully !',
  SAVE_CV_STATUS: 'CV has been saved successfully !',
  COPED_LINK_STATUS: 'Link has been copied !',
  USER_ROLE_STATUS: 'User role has been changed !',
};

export const apiEndPoints = {
  CV_ENDPOINT: '/cv',
  FOLDERS_ENDPOINT: '/folders',
  USERS_ENDPOINT: '/users',
};

export const DOWNLOAD_MESSAGE = 'Download PDF';
export const DOWNLOAD_WITHOUT_LOGO_MESSAGE = 'Download PDF without logo';
export const storagePrintKey = 'forPrint';