import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { isNil } from 'ramda';
import { useHistory } from 'react-router';
import useQuery from 'hooks/useQuery';
import { cvApi } from 'api/services/cv.api';
import { ICV } from 'models/cv';
import Loading from 'components/Loading';
import { useSelector } from 'react-redux';
import TemplateStorage from 'components/TemplateStorage';
import { selectUserImage } from 'store/cv/selectors';
import { actions } from 'store/template/slice';
import useSetAddress from 'hooks/useSetAddress';
import DownloadButtons from 'components/DownloadButtons';
import ForPrintToggle from 'components/ui/ForPrintToggle';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(6)}px 0`,
  },
  downloadWrap: {
    textAlign: 'right',
  },
}));

const PublicTemplate = () => {
  useSetAddress();
  const history = useHistory();
  const query = useQuery();
  const token = query.get('token') as string;
  const classes = useStyles();
  const [cv, setCV] = useState<ICV>();
  const userImage = useSelector(selectUserImage);

  useEffect(() => {
    (async () => {
      try {
        const { jsonData } = await cvApi.fetchPublicCVForPublicTemplate({
          token,
        });

        setCV(jsonData as ICV);
      } catch {
        history.push('/login');
      }
    })();
  }, [token]);

  return (
    <div className={classes.root}>
      <Container className={classes.downloadWrap}>
        <ForPrintToggle />
        <DownloadButtons
          toDispatch={actions.dataForTemplateReceived(cv as ICV)}
        />
      </Container>
      {isNil(cv) ? (
        <Loading />
      ) : (
        <TemplateStorage
          cv={cv}
          userImage={userImage}
        />
      )}
    </div>
  );
};

export default PublicTemplate;
