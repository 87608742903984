import { defaultBlack } from 'const/colors';
import React from 'react';

interface Props {
  language: string;
  level: string;
}

const CVLanguage = ({ language, level }: Props) => {
  return (
    <tr style={{ height: '30px' }}>
      <td
        colSpan={2}
        style={{
          marginTop: 20,
        }}
      >
        <span
          style={{
            color: defaultBlack,
            fontSize: '18px',
            fontFamily: 'Montserrat Medium',
          }}
        >
          {language}
        </span>{' '}
        - <span style={{ textTransform: 'capitalize' }}>{level}</span>
      </td>
    </tr>
  );
};

export default CVLanguage;
