import { defaultBlack } from 'const/colors';
import { format } from 'date-fns';
import { ITemplateProps } from 'models/template';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCalendarIcon, selectEmailIcon } from 'store/icons/selectors';

const CVHead = ({
  data,
  forDownload = false,
  userImage = '',
}: ITemplateProps) => {
  const emailIcon = useSelector(selectEmailIcon);
  const calendarIcon = useSelector(selectCalendarIcon);
  const iconStyles = {
    width: '12px',
    marginRight: '12px',
  };
  const avatarSize = '152px';
  const avatarMaskBorder = '40px';

  return (
    <tr>
      <td colSpan={2}>
        <div
          style={{
            marginBottom: '12px',
            color: defaultBlack,
            display: 'inline-block',
          }}
        >
          <img
            src={
              forDownload
                ? '${imgAsBase64}'
                : `${process.env.PUBLIC_URL}/images/logo.png`
            }
            style={{ width: '140px' }}
            alt="Logo"
          />

          <div
            style={{
              fontSize: '48px',
              margin: '-4px 0 -8px -4px',
              fontFamily: 'Montserrat SemiBold',
            }}
          >
            {data?.firstName} {data?.lastName}
          </div>
          <div style={{ fontSize: '28px', fontFamily: 'Montserrat Medium' }}>
            {data?.jobTitle}
          </div>

          {data?.email && (
            <div>
              <img src={emailIcon} alt="email" style={iconStyles} />
              {data?.email}
            </div>
          )}
          {data?.dateOfBirth && (
            <div>
              <img src={calendarIcon} alt="calendar" style={iconStyles} />
              {format(new Date(data?.dateOfBirth), 'dd/MM/yyyy')}
            </div>
          )}
        </div>

        {userImage && (
          <div
            style={{
              display: 'inline-block',
              float: 'right',
              position: 'relative',
            }}
          >
            <img
              src={userImage}
              style={{
                width: avatarSize,
                height: avatarSize,
                objectFit: 'cover',
                borderRadius: '50%',
              }}
              alt="avatar"
            />

            {forDownload && (
              <div
                style={{
                  position: 'absolute',
                  width: avatarSize,
                  height: avatarSize,
                  top: `-${avatarMaskBorder}`,
                  left: `-${avatarMaskBorder}`,
                  border: `${avatarMaskBorder} solid white`,
                  borderRadius: '50%',
                }}
              ></div>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

export default CVHead;
