import React from 'react';
import { format } from 'date-fns';
import { ITemplateProps } from 'models/template';
import { IProject } from 'models/cv';
import CVHead from 'components/cv/CVHead';
import { mainBlack } from 'const/colors';
import ListHeader from 'components/cv/ui/ListHeader';
import SectionHeader from 'components/cv/ui/SectionHeader';
import CVExperience from 'components/cv/CVExperience';
import CVLanguage from 'components/cv/CVLanguage';
import SkillColumn from 'components/cv/ui/SkillColumn';

const DefaultTemplate = ({
  data,
  forDownload = false,
  userImage = '',
}: ITemplateProps): React.ReactElement => {
  function checkLongerSkillSet(
    leftColumn: number[] = [
      data?.skills.length || 0,
      data?.programmingLanguages.length || 0,
      data?.frameworksAndLibraries.length || 0,
    ],
    rightColumn: number[] = [
      data?.technologiesAndDatabases.length || 0,
      data?.others.length || 0,
    ]
  ): string {
    const leftCount = leftColumn.reduce((acc, number) => acc + number);
    const rightCount = rightColumn.reduce((acc, number) => acc + number);

    if (leftCount === rightCount) return 'left';
    if (leftCount + 2 > rightCount) return 'left';
    if (leftCount < rightCount) return 'right';

    return '';
  }

  const space = (height: string) => <tr style={{ height }} />;
  const skillList = (list: [] | undefined) => (
    <ul style={{ margin: 0, padding: 0 }}>
      {list?.map((item) => (
        <li
          style={{
            display: 'block',
          }}
          key={item}
        >
          {item}
        </li>
      ))}
    </ul>
  );

  return (
    <table
      className="template"
      style={{
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        borderCollapse: 'collapse',
        fontSize: '16px',
        emptyCells: 'hide',
        fontFamily: 'Montserrat',
        color: mainBlack,
      }}
    >
      <thead>
        <CVHead
          data={data}
          forDownload={forDownload}
          userImage={userImage}
        />
      </thead>

      <tbody
        style={{
          borderCollapse: 'separate',
        }}
      >
        <tr>
          <SectionHeader
            content="Professional summary"
            fontSize="20px"
            paddingTop={10}
            forDownload={forDownload}
          />
        </tr>
        <tr>
          <td
            style={{
              fontFamily: 'Open Sans',
            }}
            colSpan={2}
          >
            {data?.professionalSummary}
          </td>
        </tr>

        {space('24px')}

        <tr>
          <SectionHeader
            content="Skills & Abilities"
            forDownload={forDownload}
          />
        </tr>
        <tr>
          <SkillColumn
            checkLongerSkillSet={checkLongerSkillSet}
            side={'left'}
            content={
              <>
                {data?.skills && !!data.skills.length && (
                  <div style={{ marginBottom: '12px' }}>
                    <ListHeader content="Skills" />
                    {skillList(data?.skills)}
                  </div>
                )}
                <div style={{ marginBottom: '12px' }}>
                  <ListHeader content="Programming languages" />
                  {skillList(data?.programmingLanguages)}
                </div>
                <div style={{ marginBottom: '12px' }}>
                  <ListHeader content="Frameworks/Libraries" />
                  {skillList(data?.frameworksAndLibraries)}
                </div>
              </>
            }
          />

          <SkillColumn
            checkLongerSkillSet={checkLongerSkillSet}
            side={'right'}
            content={
              <>
                <div style={{ marginBottom: '12px' }}>
                  <ListHeader content="Technologies/Databases" />
                  {skillList(data?.technologiesAndDatabases)}
                </div>
                <div style={{ marginBottom: '12px' }}>
                  <ListHeader content="Others" />
                  {skillList(data?.others)}
                </div>
              </>
            }
          />
        </tr>

        <tr style={{ height: '46px' }}>
          <SectionHeader
            content="Education"
            forDownload={forDownload}
          />
        </tr>

        <tr>
          <td
            colSpan={2}
            style={{ paddingTop: 8, fontFamily: 'Open Sans' }}
          >
            {data?.education.map((item, index) => {
              return (
                <div key={index}>
                  {item.school}, {item.degree}
                  <div>
                    {format(new Date(item.educationStartDate), 'yyyy')} -{' '}
                    {format(new Date(item.educationEndDate), 'yyyy')}
                  </div>
                </div>
              );
            })}
          </td>
        </tr>

        {space('25px')}

        <tr style={{ height: '46px' }}>
          <SectionHeader
            content="Languages"
            forDownload={forDownload}
          />
        </tr>

        {data?.languages.map(({ spoken, language }) => (
          <React.Fragment key={language}>
            <CVLanguage
              language={language}
              level={spoken || ''}
            />
          </React.Fragment>
        ))}

        {space('25px')}

        <tr style={{ height: '46px' }}>
          <SectionHeader
            content="Project Experience"
            forDownload={forDownload}
          />
        </tr>

        <tr>
          <td colSpan={2}>
            <div
              style={{
                paddingLeft: '20px',
                borderLeft: '2px solid black',
                marginTop: '20px',
              }}
            >
              {JSON.parse(JSON.stringify(data?.projects))
                .reverse()
                .map((project: IProject) => (
                  <CVExperience
                    project={project}
                    key={project.name}
                  />
                ))}
            </div>
          </td>
        </tr>

        {data?.hobby.replace(/\s/g, '') && (
          <>
            <tr style={{ height: '46px' }}>
              <SectionHeader
                content="Hobby"
                forDownload={forDownload}
              />
            </tr>

            <tr>
              <td
                colSpan={2}
                style={{ paddingTop: 8, fontFamily: 'Open Sans' }}
              >
                {data?.hobby}
              </td>
            </tr>
          </>
        )}

        {space('24px')}
      </tbody>
    </table>
  );
};

export default DefaultTemplate;
