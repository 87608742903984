import { AxiosResponse } from 'axios';
import { map, pick, pipe } from 'ramda';
import { API } from '../API';
import { AccessLevel, ICV, ICVDocument } from '../../models/cv';
import { IApiCRUD } from '../../models/api';
import transformJSON from '../../utils/transformJSON';
import { apiEndPoints } from '../../const';

const { CV_ENDPOINT } = apiEndPoints;
type cvJsonData = ICV | string;
interface CVResponse {
  id: number;
  jsonData: cvJsonData;
  userId: number;
  picture: any;
}

interface FileResponse {
  content: string;
  contentType: string;
  name: string;
}

export interface CVDto {
  id: number;
  jsonData: cvJsonData;
  picture?: string;
}

const transformCVResponse = (response: CVResponse): CVDto => {
  return pipe(
    pick(['id', 'jsonData']),
    transformJSON('jsonData', 'parse')
  )(response) as unknown as CVDto;
};

class CVAPI extends API implements IApiCRUD<CVDto> {
  public async create(cvFormValues: ICV): Promise<CVDto> {
    const { data } = await this.post<CVResponse>(`${CV_ENDPOINT}`, {
      jsonData: JSON.stringify(cvFormValues),
    });
    return transformCVResponse(data);
  }

  public async createForNonExistingUser(cvFormValues: ICV): Promise<CVDto> {
    const { data } = await this.post<CVResponse>(`${CV_ENDPOINT}/not-existed`, {
      jsonData: JSON.stringify(cvFormValues),
    });
    return transformCVResponse(data);
  }

  public async fetchAll(): Promise<CVDto[]> {
    const { data } = await this.get<CVResponse[]>(`${CV_ENDPOINT}`);
    return map(transformCVResponse, data);
  }

  public async fetchOne(id: number): Promise<CVDto> {
    const { data } = await this.get<CVResponse>(`${CV_ENDPOINT}/${id}`);
    return transformCVResponse(data);
  }

  public async fetchUserImage(id: number): Promise<CVDto> {
    const {
      data: { picture: userImage },
    } = await this.get<CVResponse>(`${CV_ENDPOINT}/${id}`);
    return userImage;
  }

  public async remove(id: number): Promise<AxiosResponse> {
    return this.delete(`/cv/${id}`);
  }

  public async update({ id, jsonData }: CVDto): Promise<CVDto> {
    const { data } = await this.put<CVResponse>(`${CV_ENDPOINT}`, {
      id,
      jsonData: JSON.stringify(jsonData),
    });
    return transformCVResponse(data);
  }

  public async fetchUserCV(): Promise<CVDto> {
    const { data } = await this.get<CVResponse>(`${CV_ENDPOINT}`);
    return transformCVResponse(data);
  }

  public async fetchOwnUserPicture(): Promise<CVDto> {
    const {
      data: { picture: userImage },
    } = await this.get<CVResponse>(`${CV_ENDPOINT}`);
    return userImage;
  }

  public async clone(id: number): Promise<ICVDocument> {
    const { data } = await this.put<CVResponse>(`${CV_ENDPOINT}/${id}/clone`);
    return transformJSON('jsonData', 'parse', data) as ICVDocument;
  }

  public async fetchPublicLink(id: number): Promise<string> {
    const { data } = await this.get(`${CV_ENDPOINT}/${id}/link`);
    return data as string;
  }

  public async updateCVAccess(payload: {
    id: number;
    accessLevel: AccessLevel;
  }): Promise<void> {
    await this.put(`${CV_ENDPOINT}/access`, payload);
  }

  public async fetchPublicCVForPublicTemplate(payload: {
    token: string;
  }): Promise<CVDto> {
    const { data } = await this.get<CVResponse>(
      `${CV_ENDPOINT}/public?token=${payload.token}`
    );
    return transformCVResponse(data);
  }

  public async downloadPDF(payload: {
    html: string;
    includeAvatar: boolean;
    name: string;
  }): Promise<FileResponse> {
    const { data } = await this.post(`${CV_ENDPOINT}/public/pdf`, payload);
    return data as FileResponse;
  }
}

export const cvApi = new CVAPI();
