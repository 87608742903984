import { defaultBlack, mainBlack } from 'const/colors';
import { IProject } from 'models/cv';
import React from 'react';
import ListHeader from './ui/ListHeader';
import { formatPeriod } from 'utils/formatPeriod';
import { dateSubstraction } from 'utils/dateSubstraction';

interface Props {
  project: IProject;
}

const CVExperience = ({ project }: Props) => {
  return (
    <div>
      <div style={{ marginTop: 30 }}>
        <ListHeader content={project.position} height="20px" />
        <p
          style={{
            color: defaultBlack,
            fontSize: '18px',
            fontFamily: 'Montserrat Medium',
            margin: 0,
          }}
        >
          {project.name} ({project.country})
          <span
            style={{
              color: mainBlack,
              fontFamily: 'Open Sans',
              fontSize: '16px',
            }}
          >
            {' '}
            /{' '}
            {project.period
              ? formatPeriod(project.period || 1)
              : formatPeriod(
                  dateSubstraction(
                    project.projectStartDate,
                    project.projectEndDate
                  )
                )}
          </span>
        </p>
      </div>

      <p
        style={{
          marginTop: 8,
          fontFamily: 'Open Sans',
          color: defaultBlack,
        }}
      >
        {project.projectDescription}
      </p>
      <ul
        style={{
          margin: 0,
          padding: 0,
          fontFamily: 'Open Sans',
        }}
      >
        {project.tools.map((item, index) => (
          <li
            style={{
              display: 'inline-block',
              width: '49%',
            }}
            key={index}
          >
            - {item}
          </li>
        ))}
      </ul>

      <ul
        style={{
          paddingLeft: 0,
          listStyleType: 'none',
          fontFamily: 'Open Sans',
        }}
      >
        {project.responsibilities.split('\n').map((el, index) => (
          <li style={{ margin: '3px 0' }} key={index}>
            {el} <br />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CVExperience;
