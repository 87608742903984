export const setCroppedImage = (url:any) => {
    window.localStorage.setItem('croppedImage', url);
}

export const setEmptyCroppedImage = () => {
    window.localStorage.setItem('croppedImage', '');
}

export const getCroppedImage = () => {
    return window.localStorage.getItem('croppedImage');
}

